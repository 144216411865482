@import '../../styles/base/variables';

.lightboxHeader {
  display: flex;
  justify-content: flex-end;
}

.lighboxContent {
  text-align: left;

  h1 {
    margin-bottom: $spacing-s;
  }
}
